<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form
      v-show="actived === 1"
      ref="form"
      v-model="valid"
    >
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="'password'"
                label="当前密码"
                outlined
                dense
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="新密码"
                :rules="[validators.required,validators.passwordValidator]"
                outlined
                dense
                hint="最少八个字符，至少一个大写字母，一个小写字母和一个数字"
                persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="确认新密码"
                :rules="[validators.required,validators.confirmedValidator(newPassword,cPassword)]"
                outlined
                dense
                class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <div class="pa-3 pl-lg-9">
        <!-- action buttons -->
        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="changePwd"
          >
            保存
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mt-3"
            @click="reset"
          >
            重置
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
    <send-msg
      v-show="actived === 2"
      :actived.sync="actived"
      :all-pwd="allPassword"
      :mobile="userInfo.mobile"
    ></send-msg>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import {
  confirmedValidator,
  passwordValidator,
  required,
} from '@core/utils/validation'
import { userInfo } from '@core/utils/userData'
import SendMsg from './SendMsg.vue'

export default {
  components: { SendMsg },
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const actived = ref(1)
    const allPassword = ref({})
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const changePwd = () => {
      if (valid.value) {
        actived.value = 2
        allPassword.value.currentPassword = currentPassword.value
        allPassword.value.newPassword = newPassword.value
        allPassword.value.cPassword = cPassword.value
      } else {
        validate()
      }
    }

    const reset = () => {
      form.value.reset()
    }

    return {
      form,
      valid,
      validate,
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      actived,
      allPassword,
      newPassword,
      cPassword,
      userInfo,
      changePwd,
      reset,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },

      // validation
      validators: {
        required, passwordValidator, confirmedValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
