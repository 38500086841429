<template>
  <v-card
    flat
    class="pa-4 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="multi-col-validation mt-6"
        @submit.prevent="onSubmit"
      >
        <v-row>
          <v-col>
            <div class='tips'>
              企业实名认证过程需先进行联系人实名认证，然后再进行企业实名认证。
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex justify-space-between"
            >
              <h3>
                企业信息：
              </h3>

              <div>
                <v-chip
                  :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).authStatus)"
                >
                  {{ genResolverFromOptions(authStatusOptions, 'text')(getParsedUserData(userInfo).authStatus) }}
                </v-chip>
              </div>
            </div>
            <input-group
              v-model="submitData"
              :input-group-list="companyFormComponents"
              row-class="ma-0 pa-0"
            ></input-group>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex justify-space-between"
            >
              <h3>
                联系人信息：
              </h3>

              <div>
                <v-chip
                  :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).contractAuthStatus)"
                >
                  {{ genResolverFromOptions(authStatusOptions, 'text')(getParsedUserData(userInfo).contractAuthStatus) }}
                </v-chip>
              </div>
            </div>
            <input-group
              v-model="submitData"
              :input-group-list="contractFormComponents"
              row-class="ma-0 pa-0"
            ></input-group>
          </v-col>
        </v-row>
      </v-form>

      <div>
        <div
          class="mb-2 font-weight-bold"
        >
          实名认证审核状态
        </div>

        <v-data-table
          :headers="headers"
          :items="userInfo.authorizationList ? userInfo.authorizationList : []"
          hide-default-footer
          :items-per-page="-1"
          no-data-text="暂无数据"
          no-results-text="未找到相关数据"
          loading-text="加载中，请稍等..."
          item-key="videoIndex"
          disable-sort
          disable-filtering
          disable-pagination
        >
          <template #[`item.authorizationFile`]="{item}">
            <v-chip
              v-if="item.authorizationFile"
              @click="downloadAttachment(item.authorizationFile)"
            >
              预览
            </v-chip>
          </template>

          <template #[`item.authorizationFileType`]="{item}">
            单位实名认证授权委托书(经办人授权认证)
          </template>

          <template #[`item.result`]="{item}">
            {{ genResolverFromOptions(realNameAuthResultOptions)(item.result) }}
          </template>
        </v-data-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed, reactive, ref } from '@vue/composition-api'
import { getParsedUserData, userInfo } from '@core/utils/userData'
import { requestPost } from '@core/utils/form'
import { modalEnquireTitle, modalEnquireWait, toastSuccess } from '@core/utils/prompt'
import { getDelimiterRight } from '@core/utils/dataProcess'
import {
  authStatusOptions,
  countryOptions,
  IDTypeOptions,
  realNameAuthResultOptions,
} from '@core/utils/options'
import { genResolverFromOptions } from '@core/utils/resolve'
import { required } from '@core/utils/validation'
import { AliOSS } from '@core/utils/useAliOSS'
import InputGroup from '@/views/components/common/input-group/InputGroup.vue'
import router from '@/router'

export default {
  components: {
    InputGroup,
  },
  props: {
    accountData: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props) {
    // 页面上需要的部分数据（侧边框标题，选择类组件的可选项这些）
    const pageData = reactive({
      title: 'FB批量上传Reels视频',
      authStatusArray: [],
    })

    // 需要上传的数据
    const blankData = {
      ...JSON.parse(JSON.stringify(userInfo)),
    }
    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      submitData.value = JSON.parse(JSON.stringify(props.accountData))
    }

    const onSubmit = () => {
      if (valid.value) {
        modalEnquireWait(() => requestPost('/client/userInfo/save', submitData.value), '请确认所填信息无误', '点击确定后将会保存相关信息！').then(res => {
          if (res.isConfirmed && res.value.data.status === 200) {
            toastSuccess(res.value.data.message)
          }
        })
      } else validate()
    }

    const aliOss = new AliOSS()
    aliOss.fetchOSS()

    const downloadAttachment = link => {
      window.open(link, '_blank', 'width=800,height=900,top=80,right=80,toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes')
    }

    // requestPost('/person/personalData').then(res => {
    //   console.log(res)
    // })

    const headers = reactive([
      {
        text: '组织机构名称', value: 'enterpriseName', sortable: false, align: 'center',
      },
      {
        text: '类型', value: 'authorizationFileType', sortable: false, align: 'center',
      },
      {
        text: '附件', value: 'authorizationFile', sortable: false, align: 'center',
      },
      {
        text: '审核状态', value: 'result', sortable: false, align: 'center',
      },
      {
        text: '发起时间', value: 'createdAt', sortable: false, align: 'center',
      },
      {
        text: '审核时间', value: 'authAt', sortable: false, align: 'center',
      },

      // {
      //   text: '审核意见', value: 'remark', sortable: false, align: 'center',
      // },
    ])

    // 表单组件
    const companyFormComponents = reactive([
      {
        VModelKey: 'enterpriseName',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '企业名称',
        required: true,
        disabled: true,
      },
      {
        VModelKey: 'idCard',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '社会信用代码',
        rules: [required],
        required: true,
        disabled: getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth,
      },
      {
        VModelKey: 'address',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '企业联系地址',
        required: true,
      },
      {
        VModelKey: 'mobile',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '企业电话',
        rules: [required],
        required: true,
      },
      {
        VModelKey: 'email',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '企业邮箱',
        required: true,
      },
    ])

    const companyRealNameAuth = () => {
      if (!getParsedUserData(userInfo).contractPersonAuth) {
        modalEnquireTitle('注意', '您还未进行联系人的实名认证，必须先进行联系人的实名认证！点击确定后将会跳转至联系人实名认证页。').then(res => {
          if (res.isConfirmed) {
            router.push({ name: 'real-name-auth' })
          }
        })

        return
      }
      router.push({ name: 'company-auth' })
    }

    // 表单组件
    const contractFormComponents = reactive([
      {
        VModelKey: 'contractRealName',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '姓名',
        required: true,
        disabled: getParsedUserData(userInfo).contractPersonAuth || getParsedUserData(userInfo).contractPersonNotYetAuth,
      },
      {
        colClass: 'mx-0 px-0',
        VModelKey: 'contractIdCardType',
        componentName: 'VSelect',
        label: '证件类型',
        items: IDTypeOptions,
        required: true,
        rules: [required],
        disabled: getParsedUserData(userInfo).contractPersonAuth || getParsedUserData(userInfo).contractPersonNotYetAuth,
      },
      {
        hidden: computed(() => submitData.value.contractIdCardType !== 4),
        VModelKey: 'contractNationality',
        componentName: 'VAutocomplete',
        items: countryOptions,
        colClass: 'mx-0 px-0',
        label: '国籍',
        required: true,
        rules: [required],
        disabled: getParsedUserData(userInfo).contractPersonAuth || getParsedUserData(userInfo).contractPersonNotYetAuth,
      },
      {
        VModelKey: 'contractIdCard',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '身份证号/护照号码/回乡证/台胞证',
        rules: [required],
        required: true,
        disabled: getParsedUserData(userInfo).contractPersonAuth || getParsedUserData(userInfo).contractPersonNotYetAuth,
      },
      {
        VModelKey: 'contractMobile',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '手机号',
        rules: [required],
        required: true,
      },
      {
        VModelKey: 'contractEmail',
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '邮箱地址',
        required: true,
      },
      {
        componentName: 'BtnGroup',
        colClass: 'mx-0 px-0 d-flex justify-end',
        componentProps: {
          btnGroupList: [
            {
              text: '保存',
              color: 'success',
              componentProps: {
                type: 'submit',
              },
            },
            {
              hidden: getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth,
              text: '去实名认证',
              color: 'info',
              class: 'mx-2',
              eventHandlers: {
                click: companyRealNameAuth,
              },
            },
          ],
        },
      },
    ])

    return {
      form,
      valid,
      headers,
      pageData,
      userInfo,
      submitData,
      companyFormComponents,
      contractFormComponents,
      realNameAuthResultOptions,
      onSubmit,
      validate,
      resetForm,
      downloadAttachment,

      authStatusOptions,
      getDelimiterRight,
      getParsedUserData,
      genResolverFromOptions,
    }
  },
}
</script>
<style scoped  lang='scss'>
.tips {
  font-size: 16px;
}
</style>
