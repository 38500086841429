<template>
  <div>
    <send-msg
      v-show="actived === 3"
      :actived.sync="actived"
      :mobile="cMobile"
    ></send-msg>
    <v-card
      v-show="actived !== 3"
      flat
      class="pa-3 mt-2"
    >
      <v-form class="multi-col-validation">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <p class="text--primary mt-2 mb-2">
                账单确认人手机号
              </p>
            </v-col>

            <v-col
              cols="12"
              md="5"
            >
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                  v-model="cMobile"
                  :rules="[validators.required, validators.mobileValidator]"
                  outlined
                  dense
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-btn
            color="primary"
            class="me-3 mt-3"
            @click="save"
          >
            保存
          </v-btn>
        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from '@axios'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import {
  mobileValidator,
  required,
} from '@core/utils/validation'
import { requestGet } from '@core/utils/form'
import { userInfo } from '@core/utils/userData'
import config from '../../../../config'
import SendMsg from './SendMsg.vue'

export default {
  components: { SendMsg },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const cMobile = ref('')
    const form = ref(null)
    const valid = ref(false)
    const actived = ref(1)
    const validate = () => {
      form.value.validate()
    }

    const save = () => {
      if (valid.value) {
        actived.value = 3
      } else {
        validate()
      }
    }

    requestGet(`/client/getCheckMobile/${userInfo.id}`).then(res => {
      cMobile.value = res.data
      console.log(res)
    })

    const setMobile = () => {
      axios.post(`${config.serviceUrl}/person/checkMobile/${cMobile.value}`)
        .then(response => {
          if (response.data.status === 200) {
            toastSuccess('账单确认人手机号修改成功!')
          }
        }).catch(error => {
          if (error.response.data.message) {
            modalFail(error.response.data.message)
          }

          // modalFail(error.response.data.message)
          console.log('error')
        })
    }

    const test = data => {
      localStorage.setItem('channelAuthed', data)
    }

    return {
      test,
      form,
      valid,
      cMobile,
      actived,
      save,
      setMobile,
      validate,

      // validation
      validators: {
        required, mobileValidator,
      },
    }
  },
}
</script>
