<template>
  <div
    class="container pt-8"
  >
    <div class="titleLefTop">
      验证码将发送至手机号：{{ mobile.substring(0,3) }}****{{ mobile.substring(mobile.length-4) }}
    </div>
    <div class="detail">
      如果长时间未收到验证码，请检查是否将运营商拉黑
    </div>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-text-field
        v-model="verificationCode"
        outlined
        :rules="[validators.required]"
        :error-messages="errorMsg"
        placeholder="请输入手机验证码"
      >
        <template #append>
          <v-chip
            :disabled="isCountdown"
            small
            color="primary"
            outlined
            @click="sendMsg"
          >
            {{ hint }}
          </v-chip>
        </template>
      </v-text-field>
    </v-form>
    <VCode
      :show="isShow"
      @success="onSuccess"
      @close="onClose"
    />
    <v-btn
      class="mt-4 mb-4"
      color="primary"
      @click="checkCode"
    >
      确认
    </v-btn>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api/dist/vue-composition-api'
import axios from '@axios'
import {
  required, passwordValidator, confirmedValidator, mobileValidator,
} from '@core/utils/validation'
import { modalFail, toastSuccess } from '@core/utils/prompt'
import VCode from 'vue-puzzle-vcode'
import config from '../../../../config'

export default {
  components: {
    VCode,
  },
  props: {
    actived: {
      type: Number,
      required: false,
      default: 1,
    },
    allPwd: {
      type: Object,
      required: false,
      default: Object,
    },
    mobile: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isLogin: false,
    }
  },
  mounted() {
    // 没有code执行校验
    if (!this.$route.query.code) {
      // this.getRedirectUrl()
    }
  },
  setup(props, { emit }) {
    const ssoToken = localStorage.getItem('ssoToken')
    // eslint-disable-next-line no-param-reassign
    if (ssoToken) {
      axios.defaults.headers.Authorization = `${ssoToken}`
    }

    const account = ref('')
    const password = ref('')
    const step = ref(1)
    const errorMsg = ref('')
    const hint = ref('获取短信验证码')
    const verificationCode = ref('')
    const isShow = ref(false)
    const isCountdown = ref(false)
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    function change(num) {
      emit('update:actived', num)
    }

    let num = 60
    function timeoutChangeStyle() {
      // 到达0秒后 重置时间，去除样式
      if (num === 0) {
        num = 60
        hint.value = '重新发送'
        isCountdown.value = false

        // 时间到达后清除样式
      } else {
        isCountdown.value = true
        hint.value = `${num}s后再次发送`
        // eslint-disable-next-line no-implied-eval
        setTimeout(() => {
          timeoutChangeStyle()
        }, 1000)
      }
      num -= 1
    }

    const onSuccess = msg => {
      console.log(msg)
      isShow.value = false // 通过验证后，需要手动关闭模态框
      toastSuccess('验证码已发送！')
      timeoutChangeStyle()
      axios.post(`${config.serviceUrl}/person/sendSMS/${props.mobile}`)
        .then(response => {
          if (response.data.status === 200) {
            console.log(response.data)
          }
        }).catch(error => {
          modalFail(error.response.data.message)
          console.log('error')
        })
    }

    const sendMsg = () => {
      isShow.value = true

      // axios.post(`${config.serviceUrl}/person/sendSMS/${mobile.value}`)
      //   .then(response => {
      //     if (response.data.status === 200) {
      //       toastSuccess('验证码已发送！')
      //       timeoutChangeStyle()
      //     }
      //   }).catch(error => {
      //     modalFail(error.response.data.message)
      //     console.log('error')
      //   })
    }

    const onClose = () => {
      isShow.value = false
      modalFail('验证失败，请重新验证！')
    }

    const resetPwd = () => {
      axios.post(`${config.serviceUrl}/person/changePassword`, {
        prePassword: props.allPwd.currentPassword,
        newPassword: props.allPwd.newPassword,
        newPasswordVo: props.allPwd.cPassword,
        mobile: props.mobile,
      })
        .then(response => {
          if (response.data.status === 200) {
            toastSuccess('密码修改成功！')
            emit('update:actived', 1)
          }
        }).catch(error => {
          console.log(error)
          modalFail(error.response.data.message)
          emit('update:actived', 1)
        })
    }

    const setMobile = () => {
      axios.post(`${config.serviceUrl}/person/checkMobile/${props.mobile}`)
        .then(response => {
          if (response.data.status === 200) {
            toastSuccess('账单确认人手机号修改成功!')
            emit('update:actived', 1)
          }
        }).catch(error => {
          if (error.response.data.message) {
            modalFail(error.response.data.message)
          }

          // modalFail(error.response.data.message)
          console.log('error')
        })
    }

    const checkCode = () => {
      if (valid.value) {
        axios.post(`${config.serviceUrl}/person/verify?mobile=${props.mobile}&code=${verificationCode.value}`)
          .then(response => {
            if (response.data.status === 200) {
              toastSuccess('验证码验证成功!')
              if (props.actived === 3) {
                setMobile()
              } else {
                resetPwd()
              }
            }
          }).catch(error => {
            if (error.response.data.message) {
              errorMsg.value = error.response.data.message
            }

            // modalFail(error.response.data.message)
            console.log('error')
          })
      } else {
        validate()
      }
    }

    return {
      valid,
      form,
      validate,
      change,
      account,
      password,
      step,
      errorMsg,
      hint,
      isShow,
      isCountdown,
      verificationCode,
      onSuccess,
      onClose,
      checkCode,
      sendMsg,
      setMobile,

      // validation
      validators: {
        required, passwordValidator, confirmedValidator, mobileValidator,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.titleLefTop{
  height: 17px;
  font-size: 18px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  line-height: 49px;
  margin-top: 10px;
}
.detail{
  margin-top: 37px;
  margin-bottom: 30px;
  height: 21px;
  font-size: 14px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
}
.container{
  width: 460px;
}
.errorText{
  width: 500px;
  font-size: 26px;
  font-family: Microsoft YaHei,sans-serif;
  font-weight: 400;
  color: #FD4545;
  line-height: 40px;
  margin-bottom: 12px;
}
</style>
