<template>
  <v-card
    flat
    class="pa-3 mt-2 ml-lg-10"
  >
    <v-card-text class="d-flex">
      <v-avatar
        size="120"
        class="me-6"
      >
        <v-img
          :src="submitData.avatar || require('@/assets/images/avatars/1.png')"
        ></v-img>
      </v-avatar>

      <div
        class="d-flex flex-column"
      >
        <v-chip
          :color="genResolverFromOptions(authStatusOptions, 'color')(getParsedUserData(userInfo).authStatus)"
        >
          {{ genResolverFromOptions(authStatusOptions, 'text')(getParsedUserData(userInfo).authStatus) }}
        </v-chip>
      </div>

      <!-- upload photo -->
      <!--      <div>-->
      <!--&lt;!&ndash;        <v-btn&ndash;&gt;-->
      <!--&lt;!&ndash;          color="primary"&ndash;&gt;-->
      <!--&lt;!&ndash;          class="me-3 mt-5"&ndash;&gt;-->
      <!--&lt;!&ndash;          @click="$refs.refInputEl.click()"&ndash;&gt;-->
      <!--&lt;!&ndash;        >&ndash;&gt;-->
      <!--&lt;!&ndash;          <v-icon class="d-sm-none">&ndash;&gt;-->
      <!--&lt;!&ndash;            {{ icons.mdiCloudUploadOutline }}&ndash;&gt;-->
      <!--&lt;!&ndash;          </v-icon>&ndash;&gt;-->
      <!--&lt;!&ndash;          <span class="d-none d-sm-block">更换头像</span>&ndash;&gt;-->
      <!--&lt;!&ndash;        </v-btn>&ndash;&gt;-->

      <!--&lt;!&ndash;        <input&ndash;&gt;-->
      <!--&lt;!&ndash;          ref="refInputEl"&ndash;&gt;-->
      <!--&lt;!&ndash;          type="file"&ndash;&gt;-->
      <!--&lt;!&ndash;          accept=".jpeg,.png,.jpg,GIF"&ndash;&gt;-->
      <!--&lt;!&ndash;          :hidden="true"&ndash;&gt;-->
      <!--&lt;!&ndash;        />&ndash;&gt;-->

      <!--        <v-btn-->
      <!--          color="error"-->
      <!--          outlined-->
      <!--          class="mt-5"-->
      <!--        >-->
      <!--          Reset-->
      <!--        </v-btn>-->
      <!--        <p class="text-sm mt-5">-->
      <!--          允许格式： JPG, GIF 或 PNG. 最大尺寸 800K-->
      <!--        </p>-->
      <!--      </div>-->
    </v-card-text>

    <v-card-text>
      <v-form
        ref="form"
        v-model="valid"
        class="multi-col-validation mt-6"
        @submit.prevent="onSubmit"
      >
        <input-group
          v-model="submitData"
          :input-group-list="formComponents"
          row-class="ma-0 pa-0"
        ></input-group>

        <v-row>
          <!--          <v-col cols="12">-->
          <!--            <v-btn-->
          <!--              color="primary"-->
          <!--              class="me-3 mt-4"-->
          <!--            >-->
          <!--              保存-->
          <!--            </v-btn>-->
          <!--            <v-btn-->
          <!--              color="secondary"-->
          <!--              outlined-->
          <!--              class="mt-4"-->
          <!--              type="reset"-->
          <!--              @click.prevent="resetForm"-->
          <!--            >-->
          <!--              重置-->
          <!--            </v-btn>-->
          <!--          </v-col>-->
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { computed, reactive, ref } from '@vue/composition-api'
import { authStatusOptions, countryOptions, IDTypeOptions } from '@core/utils/options'
import { getParsedUserData, userInfo } from '@core/utils/userData'
import { requestPost } from '@core/utils/form'
import { required } from '@core/utils/validation'
import { genResolverFromOptions } from '@core/utils/resolve'
import { modalEnquireWait, toastSuccess } from '@core/utils/prompt'
import { AliOSS } from '@core/utils/useAliOSS'
import { getDelimiterRight } from '@core/utils/dataProcess'
import InputGroup from '@/views/components/common/input-group/InputGroup.vue'

export default {
  components: {
    InputGroup,
  },
  props: {
    accountData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    // 页面上需要的部分数据（侧边框标题，选择类组件的可选项这些）
    const pageData = reactive({
      title: 'FB批量上传Reels视频',
    })

    // 需要上传的数据
    const blankData = {
      ...props.accountData,
      ...userInfo,
    }
    const submitData = ref(JSON.parse(JSON.stringify(blankData)))

    console.log(submitData.value)

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      submitData.value = JSON.parse(JSON.stringify(blankData))
    }

    const onSubmit = () => {
      if (valid.value) {
        modalEnquireWait(() => requestPost('/client/userInfo/save', submitData.value), '请确认所填信息无误', '点击确定后将会保存相关信息！').then(res => {
          if (res.isConfirmed && res.value.data.status === 200) {
            toastSuccess(res.value.data.message)
          }
        })
      } else validate()
    }

    const aliOss = new AliOSS()
    aliOss.fetchOSS()

    const downloadAttachment = (key, fileName) => {
      debugger
      window.open(aliOss.fetchDownloadLink(key, fileName, true))
    }

    const headers = reactive([
      {
        text: '组织机构名称', value: 'enterpriseName', sortable: false, align: 'center',
      },
      {
        text: '类型', value: 'enterpriseName', sortable: false, align: 'center',
      },
      {
        text: '附件', value: 'authorizationFile', sortable: false, align: 'center',
      },
      {
        text: '审核状态', value: 'enterpriseName', sortable: false, align: 'center',
      },
      {
        text: '发起时间', value: 'enterpriseName', sortable: false, align: 'center',
      },
      {
        text: '审核时间', value: 'enterpriseName', sortable: false, align: 'center',
      },
      {
        text: '审核意见', value: 'enterpriseName', sortable: false, align: 'center',
      },
    ])

    // 表单组件
    const formComponents = reactive([
      {
        VModelKey: 'name',
        mdCols: 7,
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: 'CP合作方姓名',
        required: true,
        disabled: true,
      },
      {
        mdCols: 7,
        colClass: 'mx-0 px-0',
        VModelKey: 'idCardType',
        componentName: 'VSelect',
        label: '证件类型',
        items: IDTypeOptions,
        required: true,
        rules: [required],
        disabled: getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth,
      },
      {
        hidden: computed(() => submitData.value.idCardType !== 4),
        VModelKey: 'nationality',
        mdCols: 7,
        componentName: 'VAutocomplete',
        items: countryOptions,
        colClass: 'mx-0 px-0',
        label: '国籍',
        required: true,
        rules: [required],
        disabled: getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth,
      },
      {
        VModelKey: 'idCard',
        mdCols: 7,
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '身份证号/护照号码/回乡证/台胞证',
        required: true,
        rules: [required],
        disabled: getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth,
      },
      {
        VModelKey: 'mobile',
        mdCols: 7,
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '手机号',
        required: true,
        rules: [required],
      },
      {
        VModelKey: 'email',
        mdCols: 7,
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '邮箱',
        required: true,
      },
      {
        VModelKey: 'address',
        mdCols: 7,
        componentName: 'VTextField',
        colClass: 'mx-0 px-0',
        label: '联系地址',
        required: true,
      },
      {
        mdCols: 7,
        componentName: 'BtnGroup',
        colClass: 'mx-0 px-0 d-flex justify-end',
        componentProps: {
          btnGroupList: [
            {
              text: '保存', color: 'success', componentProps: { type: 'submit' },
            },
            {
              text: '去实名认证', color: 'info', class: 'mx-2', componentProps: { to: { name: 'real-name-auth' } }, hidden: getParsedUserData(userInfo).auth || getParsedUserData(userInfo).notYetAuth,
            },
          ],
        },
      },
    ])

    return {
      form,
      valid,
      headers,
      pageData,
      submitData,
      formComponents,
      userInfo,
      getParsedUserData,
      onSubmit,
      validate,
      resetForm,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },

      downloadAttachment,

      getDelimiterRight,
      authStatusOptions,
      genResolverFromOptions,
    }
  },
}
</script>
